import Layout from "../components/layout/layout"
import React from "react"
import SEO from "../components/seo.js"
import { graphql } from 'gatsby';

const Annexes = ({data, pageContext, location }) => {
  
  const seo = pageContext.page.seo;

  return (
    <Layout location={location}>
      <SEO meta={seo} />
      <section className={"simple-page container"}>
        <div dangerouslySetInnerHTML={{ __html: data.page.content }} />
      </section>
    </Layout>
  )
}

export default Annexes


/*
|--------------------------------------------------------------------------
| QUERY
|--------------------------------------------------------------------------
*/
export const query = graphql`
  query pageSimple($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      content
    }
  }
`;

